export { AsignacionService } from './asignacion/asignacion.service';
export { CapacitacionService } from './capacitacion/capacitacion.service';
export { ClienteService } from './cliente/cliente.service';
export { ConfiguracionService } from './configuracion/configuracion.service';
export { ConsultaService } from './consulta/consulta.service';
export { DivulgacionService } from './divulgacion/divulgacion.service';
export { EncriptarService } from './encriptar/encriptar.service';
export { GeneralService } from './general/general.service';
export { OrdenCargaService } from './ordenCarga/orden-carga.service';
export { PeajeService } from './peaje/peaje.service';
export { PushService } from './push/push.service';
export { ReporteService } from './reporte/reporte.service';
export { RutaService } from './ruta/ruta.service';
export { TarjetaRutaService } from './tarjeta-ruta/tarjeta-ruta.service';
export { UsuarioService } from './usuario/usuario.service';
export { VehiculoService } from './vehiculo/vehiculo.service';
